






























import { defineComponent } from "@vue/composition-api"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"
import useWordCloud from "./useWordCloud"

export default defineComponent({
  name: "WordCloudMobile",
  components: { TextField, ResizableText },
  setup() {
    const {
      textField,
      timer,
      locked,
      maxTextLength,
      onSubmit,
      mission,
      answers,
      isPlayMode,
      isResultsMode
    } = useWordCloud()
    return {
      textField,
      timer,
      locked,
      maxTextLength,
      onSubmit,
      mission,
      answers,
      isPlayMode,
      isResultsMode
    }
  }
})
