import { Mode } from "@shared/enums"
import useStore from "@/use/useStore"
import { computed, nextTick, onBeforeUnmount, ref } from "@vue/composition-api"

import { createPlay } from "@/mixins/Game.js"
import { chain } from "lodash"

const TIMEOUT = 2

export default function useWordCloud() {
  const locked = ref(false)
  const timer = ref(TIMEOUT)
  const textField = ref(null)
  let timerID = null
  let timeoutID = null

  const { store } = useStore()
  const mission = computed(() => store.getters.getCurrentMission)
  const maxTextLength = computed(() => mission.value.maxTextLength || 120)
  const viewer = computed(() => store.getters["auth/user"])
  const game = computed(() => store.getters.game)
  const mode = computed(() => store.getters.getCurrentMode)

  const isPlayMode = computed(() => mode.value === Mode.Play)
  const isExplainMode = computed(() => mode.value === Mode.Explain)
  const isResultsMode = computed(() => mode.value === Mode.Results)

  const answers = computed<{ word: string; number: number }[]>(() => {
    const value = chain(store.getters.missionPlays)
      .sortBy("id")
      .map(play => String(play.correct).toLowerCase().trim())
      .value()

    const grouped: { [key: string]: number } = value.reduce((acc, word) => {
      acc[word] = acc[word] + 1 || 1
      return acc
    }, {})

    return Object.entries(grouped)
      .map(([word, number]) => ({ word, number }))
      .sort((a, b) => b.number - a.number)
  })

  function lockUI() {
    locked.value = true
    timeoutID = setTimeout(() => {
      locked.value = false
      clearInterval(timerID)

      nextTick(() => {
        textField.value.$refs.input.focus()
      })
    }, TIMEOUT * 1000)
  }

  function runTimer() {
    timer.value = TIMEOUT
    timerID = setInterval(() => {
      if (timer.value === 1) {
        return (timer.value = TIMEOUT)
      }
      timer.value -= 1
    }, 1000)
  }

  function onSubmit(data) {
    if (!data) return 0

    lockUI()
    runTimer()

    const play = createPlay({
      user: viewer.value,
      mission: mission.value,
      answer: [data],
      game: game.value,
      teamID: viewer.value.teamID
    })

    play.correct = data
    play.show = false

    store.dispatch("addPlay", play)
  }

  onBeforeUnmount(() => {
    clearInterval(timerID)
    clearTimeout(timeoutID)
  })

  return {
    answers,
    mode,
    isPlayMode,
    isExplainMode,
    isResultsMode,
    viewer,
    mission,
    textField,
    timer,
    locked,
    maxTextLength,
    onSubmit
  }
}
