var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "word-cloud-mobile" },
    [
      _vm.isPlayMode
        ? [
            _c("ResizableText", {
              staticClass: "word-cloud-mobile__text",
              attrs: { message: _vm.mission.instructions, maxFont: 26 },
            }),
            _c("TextField", {
              ref: "textField",
              staticClass: "word-cloud-mobile__field",
              attrs: {
                size: "sm",
                placeholder: "Type here ...",
                show: "show",
                status: "active",
                allowFreeSubmit: "",
                buttonText: _vm.locked ? _vm.timer : "Submit",
                disabled: _vm.locked,
                maxTextLength: _vm.maxTextLength,
              },
              on: { onSubmit: _vm.onSubmit },
            }),
          ]
        : _vm._e(),
      _vm.isResultsMode
        ? _c(
            "ul",
            { staticClass: "word-cloud-mobile__list" },
            _vm._l(_vm.answers, function (answer) {
              return _c("li", { key: answer.word }, [
                _vm._v(" " + _vm._s(answer.word + " x" + answer.number) + " "),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }